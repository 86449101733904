/* ************************
   03.3: Blog
   ********************* */

.single-post-item {
  @extend %box-shadow;
  @extend %trans3;
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
  color: var(--white);
  position: relative;
  .post-content {
    padding: 38px 30px 45px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90%;
    z-index: 1;
    &:after {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: -1;
      @extend %gradient1;
      @extend %trans3;
      content: "";
      opacity: 0.9;
    }
    .category,
    .post-title {
      margin-bottom: 15px;
      @extend %trans3;
      color: var(--white);
    }
    .post-title {
      &:hover {
        text-decoration: underline;
      }
    }
    .category {
      text-transform: uppercase;
      font-size: 13px;
      @extend %title-font;
      @extend %regular;
      margin-bottom: 16px;
      display: block;
    }
    p:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .meta {
    @extend %list-unstyled;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    li {
      &:not(:first-child) {
        position: relative;
        padding-left: 50px;
        &:after {
          background-color: rgba(#fff, 0.66);
          position: absolute;
          content: "";
          left: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          height: 1px;
        }
      }
      a {
        color: var(--white);
        img {
          margin-right: 5px;
        }
      }
    }
  }

  &:hover {
    .post-content {
      &:after {
        @extend %gradient1;
        opacity: 1;
      }
    }
  }
}

.insta_feed {
  position: relative;
  .btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }
  ul {
    @extend %list-unstyled;
    display: flex;
    flex-wrap: wrap;
  }
}

/* Blog Details */
.post-details .entry-header .entry-thumbnail {
  margin-bottom: 48px;
  border-radius: 10px;
  overflow: hidden;
  @include mobileSm {
    margin-bottom: 31px;
  }
  img {
    border-radius: 10px;
  }
}

.post-details .entry-header .entry-title {
  font-size: 60px;
  line-height: 1.28;
  margin-bottom: 6px;
  color: var(--white);
  @include mobileMd {
    font-size: 48px;
  }
}

.post-details .entry-header .entry-meta {
  margin-bottom: 30px;
  @include mobileSm {
    margin-bottom: 24px;
  }
}

.post-details .entry-header .entry-meta li {
  display: inline-block;
  @extend %title-font;
  a {
    color: var(--white);
    opacity: 0.66;
    &:hover {
      opacity: 1;
    }
  }
}

.post-details .entry-header .entry-meta li:not(:last-child) {
  margin-right: 24px;
}

.post-details .entry-header .entry-meta li span {
  font-family: var(--title-font);
  color: var(--white);
  @extend %medium;
}

.post-details .entry-footer {
  border-color: red;
}

.post-details .entry-tags ul li:first-child,
.post-details .entry-categories ul li:first-child {
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
  color: var(--white);
}

.post-details .entry-tags ul li a,
.post-details .entry-categories ul li a {
  font-size: 14px;
  line-height: 1.57;
  @extend %title-font;
  color: var(--white);
  opacity: 0.66;
  &:hover {
    opacity: 1;
  }
}

.post-details .entry-tags ul li {
  @extend %title-font;
}

.post-details .entry-tags ul li a:not(:last-child):after,
.post-details .entry-categories ul li a:not(:last-child):after {
  content: ",";
}

/* Blog Details Content */
.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6,
.entry-content .woocommerce-checkout .form-row.notes label,
.woocommerce-checkout .form-row.notes .entry-content label,
.entry-content .login-register-wrapper .tab-btn li,
.login-register-wrapper .tab-btn .entry-content li,
.comment-content h1,
.comment-content h2,
.comment-content h3,
.comment-content h4,
.comment-content h5,
.comment-content h6,
.comment-content .woocommerce-checkout .form-row.notes label,
.woocommerce-checkout .form-row.notes .comment-content label,
.comment-content .login-register-wrapper .tab-btn li,
.login-register-wrapper .tab-btn .comment-content li,
.page--content h1,
.page--content h2,
.page--content h3,
.page--content h4,
.page--content h5,
.page--content h6,
.page--content .woocommerce-checkout .form-row.notes label,
.woocommerce-checkout .form-row.notes .page--content label,
.page--content .login-register-wrapper .tab-btn li,
.login-register-wrapper .tab-btn .page--content li {
  line-height: 1.5;
  margin-bottom: 20px;
}

.entry-content {
  margin-bottom: 55px;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  padding-top: 16px;
  margin-bottom: 13px;
}

.entry-content p,
.comment-content p,
.page--content p {
  margin-bottom: 25px;
}

.entry-content table,
.comment-content table,
.page--content table {
  margin-bottom: 30px;
}

.entry-content select,
.comment-content select,
.page--content select {
  margin-bottom: 30px;
}

.entry-content > *:last-child,
.comment-content > *:last-child,
.page--content > *:last-child {
  margin-bottom: 0;
}

.entry-content a:hover,
.comment-content a:hover,
.page--content a:hover {
  text-decoration: underline;
}

.comment-content {
  .comment-reply-link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: var(--white);
    svg {
      margin-right: 5px;
      path {
        @extend %trans3;
      }
    }
    &:hover {
      text-decoration: none;
      color: var(--c1);
      svg {
        path {
          fill: var(--c1);
        }
      }
    }
  }
}

/* Post Author Info */
.post-author {
  padding: 60px 45px;
  border-radius: 10px;
  color: var(--white);
  h3 {
    color: var(--white);
  }
  @include mobileSm {
    padding: 40px 15px;
  }
}

@media only screen and (max-width: 575px) {
  .post-author {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

.post-author .author-thumb {
  margin-right: 30px;
}

@media only screen and (max-width: 575px) {
  .post-author .author-thumb {
    margin-bottom: 20px;
  }
}

.post-author .author-info h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

/* Post Navigation */
.post-navigation {
  .nav-link {
    flex-basis: 50%;
    max-width: 50%;
    padding: 0;
    &:first-child {
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
      .nav-title {
        i {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
}

.post-navigation .nav-link .nav-title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 17px;
  color: var(--text-color);
  &:hover {
    @extend %c1;
  }
}

.post-navigation .nav-link .nav-title i {
  margin-right: 10px;
}

.post-navigation .nav-link .nav-title.text-right i {
  margin-left: 10px;
}

.post-navigation .nav-link .posted-on {
  margin: 10px 0 2px;
  display: block;
  font-size: 14px;
}

.post-navigation .nav-link:hover .nav-title {
  opacity: 1;
}

/* Related Post */
.related-post h2 {
  font-size: 30px;
  margin-bottom: 30px;
}

/* Post Comments */
.comments-area {
  padding: 55px 45px 53px;
  border-radius: 10px;
  color: var(--white);
  h3 {
    color: var(--white);
  }
  @include mobileSm {
    padding: 36px 15px 40px;
  }
}

.comment-respond {
  padding: 54px 45px 60px;
  border-radius: 10px;
  @include mobileSm {
    padding: 34px 15px 40px;
  }
}

.comments-area .comments-title {
  margin-bottom: 38px;
}

.comments-area ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.comments-area .comment .single-comment {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #071838;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment .single-comment {
    flex-direction: column;
  }
}

.comments-area .comment .single-comment .comment-author-image {
  min-width: 65px;
  margin-right: 30px;
  border-radius: 50%;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment .single-comment .comment-author-image {
    margin-bottom: 16px;
  }
}

.comments-area
  .comment
  .single-comment:not(.trackback):not(.pingback)
  .comment-content {
  max-width: calc(100% - 95px);
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .comments-area
    .comment
    .single-comment:not(.trackback):not(.pingback)
    .comment-content {
    max-width: 100%;
  }
}

.comments-area .comment .single-comment .comment-content .author_name {
  margin-right: 20px;
  margin-bottom: 0;
  color: var(--white);
}

.comments-area .comment .single-comment .comment-content .commented-on {
  font-size: 13px;
  @extend %title-font;
}

.comments-area .comment .single-comment .comment-content p {
  margin: 13px 0 6px;
}

.comments-area .comment ul.children {
  margin-left: 95px;
}

@media only screen and (max-width: 575px) {
  .comments-area .comment ul.children {
    margin-left: 30px;
  }
}

/* Comments Form */
.comment-respond .comment-reply-title {
  margin-bottom: 20px;
  color: var(--white);
}

.comment-respond .comment-form .comment-notes {
  margin-bottom: 30px;
  color: var(--white);
}

.comment-respond .comment-form .entry-content select,
.entry-content .comment-respond .comment-form select,
.comment-respond .comment-form .comment-content select,
.comment-content .comment-respond .comment-form select,
.comment-respond .comment-form .page--content select,
.page--content .comment-respond .comment-form select {
  margin-bottom: 35px;
}

.comment-respond .comment-form .custom-checkbox,
.comment-respond .comment-form .color-container {
  margin-bottom: 25px;
}

.comment-respond .comment-form label {
  font-size: 18px;
  @extend %title-font;
  color: var(--white);
  margin-bottom: 16px;
}

.comment-respond p.custom-checkbox,
.comment-respond p.color-container {
  display: block;
  white-space: normal;
}
