/* ************************
   02.2: Animations
   ********************* */
    .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
    }
    
    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        
        100% {
            opacity: 1;
        }    
    }
        
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
    
        100%{
            opacity: 1;
        }
    }
      
    .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }

    @keyframes fadeInDown {
        0% {
        opacity: 0;
        -webkit-transform: translate3d(0, -40px, 0);
        transform: translate3d(0, -40px, 0);
        }
        to {
        opacity: 1;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        }
    }
    .fadeInDown {
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
    }
    
    @keyframes matrix {
        0% {
            transform: matrix(1, 0, 0, 1, 0, 0);
        }
        25% {
            transform: matrix(1, 0, 0, 1, 15, 20);
        }
        50% {
            transform: matrix(1, 0, 0, 1, -5, 25);
        }
        75% {
            transform: matrix(1, 0, 0, 1, -15, 15);
        }
        100% {
            transform: matrix(1, 0, 0, 1, 0, 0);
        }
    }

    @keyframes ripple {
        from {
            width: 0.1%;
            height: 0.1%;
            opacity: 1;
        }
        to {
            width: 100%;
            height: 100%;
            opacity: 0;
        }
    }