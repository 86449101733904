/* ************************
   03.1: Header
   ********************* */

// Header Main
.header-main {
  min-height: 60px;
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 9;
  &.fixed-top {
    position: fixed;
  }
  .header-inner {
    background-color: #2c3a55;
    border-radius: 10px;
    margin-top: 30px;
    padding: 0px 30px;
    position: relative;
    @include medium {
      padding: 10px 20px;
    }
    &:after {
      position: absolute;
      left: 10px;
      top: 10px;
      border-radius: 10px;
      z-index: -1;
      background-color: rgba(#2c3a55, 0.2);
      content: "";
      width: calc(100% - 20px);
      height: 100%;
    }
  }

  .logo {
    .sticky-logo {
      display: none;
    }
  }

  @include customMax(1199) {
    .submenu-button {
      position: absolute;
      right: 0;
      top: 10px;
      height: 30px;
      width: 30px;
      color: inherit;
      font-size: 14px;
      z-index: 9999;
      transition: 0.2s ease-in-out;
      &:after {
        font-family: "Font Awesome 5 Free";
        content: "\f0d7";
        line-height: 30px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-weight: 900;
      }
      &.submenu-opened:after {
        content: "\f0d8";
        color: var(--c1);
      }
    }

    .nav-wrap-inner {
      background-color: var(--white);
      box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 110%;
      z-index: 9;
      max-height: 400px;
      overflow-y: auto;
      @include medium {
        display: none;
      }
    }
  }

  ul {
    @extend %list-unstyled;
    &.nav {
      @include customMax(1199) {
        flex-direction: column;
        padding: 14px 20px;
        padding-right: 0;
        > li {
          width: 100%;
        }
      }
      > li {
        > a {
          line-height: 80px;
          position: relative;
          display: block;
          color: var(--white);
          @include medium {
            color: var(--title-color);
            line-height: 50px;
          }
        }
        &.has-sub-item {
          position: relative;
          &:after {
            color: var(--white);
            right: 10px;
          }
        }

        &.current-menu-parent,
        &:hover {
          > a {
            @extend %c1;
          }
        }
      }
      li:hover > ul {
        top: 100%;
      }

      ul {
        position: absolute;
        left: 0;
        top: -2000px;
        opacity: 0;
        visibility: hidden;
        background-color: var(--white);
        width: 200px;
        transform: translate3d(0, -5px, 0);
        transition: transform 0.3s ease-out;
        z-index: 9;
        border-radius: 0 0 5px 5px;
        padding: 0px;
        box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.16);
        @include customMax(1199) {
          position: static;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
          width: 100%;
          transform: none;

          li {
            margin-left: 20px;

            a {
              &:before,
              &:after {
                display: none;
              }
            }
          }
        }
        li {
          padding: 8px 20px;
          @include customMax(1199) {
            padding-left: 0;
            padding-right: 0;
          }
          a {
            display: block;

            &:after {
              position: absolute;
              left: 0;
              @extend %c1-bg;
              content: "";
              transition: all 0.3s ease-out;
            }
          }

          &:hover,
          &.current-menu-children {
            a {
              @extend %c1;
            }
          }

          &:not(:last-child) {
            a {
              &:after {
                bottom: 0;
                height: 1px;
                width: 100%;
                opacity: 0.1;
              }
            }
          }

          &:hover > ul {
            top: 0;
          }
        }

        ul {
          left: 100%;
        }

        &.open {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    li {
      position: relative;
      padding: 0 25px;
      @include customMax(1199) {
        padding: 0;
      }
      a {
        text-transform: capitalize;
      }
      &.has-sub-item {
        position: relative;
        &:after {
          position: absolute;
          content: "+";
          right: 20px;
          top: 50%;
          color: var(--title-color);
          transform: translateY(-50%);
          @extend %trans3;
          @include medium {
            display: none !important;
          }
        }
        &:hover,
        &.current-menu-parent {
          &:after {
            color: var(--c1);
          }
        }
      }

      &:hover > ul {
        opacity: 1;
        visibility: visible;
        top: 100%;
        transform: translate3d(0, 0, 0);
      }
    }
    &.dropdown-menu {
      li {
        padding: 0;
      }
    }
  }

  &.sticky {
    background-color: var(--white);
    min-height: 80px;
    .header-inner {
      margin-top: 0;
      padding: 0;
      background-color: transparent;
      &:after {
        display: none;
      }
    }
    ul {
      &.nav {
        > li {
          > a {
            color: var(--title-color);
          }
          &.has-sub-item {
            &:after {
              color: var(--title-color);
            }
          }

          &.current-menu-parent,
          &:hover {
            > a {
              @extend %c1;
            }
            &:after {
              color: var(--c1);
            }
          }
        }
      }
    }
    .search-form {
      .form-group {
        button {
          border: 1px solid rgba(#2c3a55, 0.5);
          svg {
            path {
              fill: #2c3a55;
            }
          }
        }

        input {
          color: var(--title-color);
          &::placeholder {
            color: #2c3a55 !important;
            opacity: 0.51 !important;
          }
        }
      }
    }
    .logo {
      .sticky-logo {
        display: inline-block;
      }
      .main-logo {
        display: none;
      }
    }
    #menu-button {
      span {
        background-color: var(--title-color);
        &:before,
        &:after {
          background-color: var(--title-color);
        }
      }
    }
  }

  &.style {
    &--two {
      .header-inner {
        margin-top: 20px;
        &:after {
          display: none;
        }
      }
      &.sticky {
        .header-inner {
          margin-top: 0;
        }
      }
    }
    &--three {
      background-color: var(--body-bg);
      .header-inner {
        &:after {
          display: none;
        }
      }
      ul.nav > li > a {
        line-height: 100px;
        @include medium {
          line-height: 50px;
        }
      }
      &.sticky {
        background-color: var(--white);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        ul.nav > li > a {
          line-height: 80px;
          @include medium {
            line-height: 50px;
          }
        }
      }
    }
    &--four {
      background-color: var(--white);
      .header-inner {
        &:after {
          display: none;
        }
      }
      #menu-button {
        span,
        span:before,
        span:after {
          background-color: var(--title-color);
        }
      }
      .search-form {
        .form-group {
          button {
            border: 1px solid rgba(#2c3a55, 0.5);
            svg {
              path {
                fill: #2c3a55;
              }
            }
          }

          input {
            color: var(--title-color);
            &::placeholder {
              color: #2c3a55 !important;
              opacity: 0.51 !important;
            }
          }
        }
      }
      ul {
        &.nav {
          > li {
            > a {
              line-height: 100px;
              color: var(--title-color);
              @include medium {
                line-height: 50px;
              }
            }
            &.has-sub-item {
              &:after {
                color: var(--title-color);
              }
            }

            &.current-menu-parent,
            &:hover {
              > a {
                @extend %c1;
              }
              &:after {
                color: var(--c1);
              }
            }
          }
        }
      }
      &.sticky {
        background-color: var(--white);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        ul.nav > li > a {
          line-height: 80px;
          @include medium {
            line-height: 50px;
          }
        }
      }
    }
  }
}

/* Menu Button */
#menu-button {
  display: none;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  @include customMax(1199) {
    display: inline-flex;
  }
  span {
    position: relative;
    display: block;
    width: 25px;
    height: 2px;
    @extend %white-bg;
    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 25px;
      height: 2px;
      @extend %white-bg;
      @extend %trans3;
    }
    &:before {
      top: -7px;
    }
    &:after {
      top: 7px;
    }
  }

  &.menu-opened {
    span {
      background-color: transparent !important;
      &:before {
        transform: translateY(7px) rotate(45deg);
      }
      &:after {
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }
}

/* User Profile */
.user {
  &-avatar {
    position: relative;
    border-radius: 50%;
    display: inline-block;
    img {
      border-radius: 50%;
    }
  }
}

.user-status,
.show-select {
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
  width: 15px;
  height: 15px;
  background-color: var(--c1);
  bottom: 0;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  &.white {
    background-color: var(--white);
    width: 10px;
    height: 10px;
  }
}
