/* ************************
   04.5: Collection
   ********************* */

.single-collection {
  padding: 21px 24px;
  border-radius: 10px;
  background-color: #142442;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
  &:after {
    content: attr(data-count);
    position: absolute;
    top: -10px;
    right: -4px;
    @extend %medium;
    font-size: 60px;
    font-family: var(--title-font);
    opacity: 0.03;
    color: var(--white);
    line-height: 1;
  }
  .collection-img {
    min-width: 39px;
    margin-right: 16px;
    img {
      border-radius: 50%;
    }
  }
  .content {
    line-height: 1;
    h5,
    span {
      color: var(--white);
    }
    h5 {
      margin-bottom: 10px;
      @extend %medium;
    }
    span {
      font-size: 14px;
    }
  }
}
