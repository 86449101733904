/* ************************
   03.2: Footer
   ********************* */

.footer {
  position: relative;
  z-index: 1;
  .widget {
    margin-bottom: 60px;

    .widget_title {
      position: relative;
      color: var(--white);
      margin-top: -5px;
      margin-bottom: 30px;
      padding-bottom: 12px;
      &:after {
        width: 19px;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        background-repeat: no-repeat;
        background-image: url(../img/icons/dot.svg);
      }
    }
  }
}

.footer-main {
  background-color: #142442;
  padding-top: 80px;
  padding-bottom: 19px;
}

.footer-middle {
  padding: 20px 0;
}

.footer-bottom {
  padding: 26px 0;
}

//Footer Menu
.footer-menu {
  @extend %list-unstyled;
  display: flex;
  align-items: center;
  li {
    &:not(:first-child) {
      margin-left: 20px;
    }
    a {
      color: var(--white);
    }
  }
}

//Footer Wrap
.footer-overlay {
  position: relative;
  z-index: 1;
  &:after {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    background-color: #071838;
    opacity: 0.95;
    content: "";
    z-index: -1;
  }
}

//Logo Wrap
.logo-wrap {
  img {
    padding: 15px;
  }
}
