/* ************************
   02.11: Preloader
   ********************* */

.preloader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2000;
}

.preloader .preloader-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #FF0076;
    background-color: #fff;
}

.preloader .preloader-svg {
    fill: transparent;
    stroke: currentColor;
    stroke-dasharray: 640;
    stroke-dashoffset: 640;
}