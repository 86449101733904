/* ************************
   02.3: Social List
   ********************* */

.socials {
  a {
    width: 45px;
    height: 45px;
    @extend %radius;
    background-color: rgba(#fff, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    position: relative;
    z-index: 1;
    &:not(:last-child) {
      margin-right: 5px;
    }
    &:after {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute;
      z-index: -1;
      @extend %gradient1-reverse;
      content: "";
      border-radius: 50%;
      transform: scale(0.7);
      opacity: 0;
      @extend %trans3;
    }
    &:hover {
      &:after {
        transform: scale(1);
        opacity: 1;
      }
    }
  }
  &.style {
    &--two {
      > span {
        @extend %bold;
        margin-right: 5px;
      }
      a {
        width: 30px;
        height: 30px;
        svg {
          width: 12px;
        }
      }
    }
  }
}
